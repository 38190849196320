/* Custom Font */
/* @font-face {
  font-family: Centra;
  src: url('.assets/fonts/CentraNo2-Bold.tff');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('.assets/fonts/CentraNo2-Medium.tff');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('.assets/fonts/CentraNo2-Book.tff');
  font-weight: 400;
} */


/* Default Css */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body{
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: Arial, Helvetica ,sans-serif !important;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
  line-height: normal;
}


/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
    width: 10rem;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

/* for the single button 'lets connect' */
#contactBtn {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
#contactBtn span {
  z-index: 1;
}
#contactBtn::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
#contactBtn:hover {
  color: #121212;
}
#contactBtn:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}


nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}


/* Banner CSS */
.banner{
  margin-top: 0;
  padding: 260px 0 100px 0;
  background: url(assets/images/banner-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8x 10px;
  background: linear-gradient(90.21deg, rgba(170,54,124,0.5) -5.91%, rgba(74, 47, 189, 0.5) );
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size:  20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1{
  font-size: 2.5em;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1em;
  margin-bottom: 20px;
  display: block;
  min-height: 65px;
}

.col-xl-7 {
  background-color: #121212;
  border-radius: 64px;
}

.banner p{
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button{
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
}

.banner .connectBtnSm {
  padding : 0 0 25px 0;
}

.banner button svg{
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg{
  margin-left: 25px;
}

.banner img{
  animation: updown 3s linear infinite;
}

@keyframes updown{
  0%{
    transform: translateY(-20px);
  }
  50%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(-20px);
  }
}

txt-rotate > .wrap{
  border-right: 0.08em solid #666;
}



/* Skills CSS */
.skill{
  padding: 0 0 50px 0;
  position: relative;
}

.skill-bx{
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.skill h2{
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.8px;
  margin-bottom: 20px;
}

.skill p{
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider{
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img  {
  width: 50%;
  margin: 0 auto 15px auto;
}


.background-image-left{
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/* Projects css  */
.projects{
  margin: -60px 0 0 0;
  padding: 100px 60px 100px 60px;
  background: url(assets/images/bgimg_projects.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.CardContents h6{
  color: black;
  font-size: 20px;
  font-weight: 600;
  padding: 20px 0 0 0;
}

.projects p{
  color: #fff;
  font-size: 18px;
  /* letter-spacing: 0.8px; */
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.projects h2{
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.8px;
  margin-bottom: 20px;
}

.projectsContainer{
  background: #151515;
  padding: 100px 0 100px 0;
  min-width: 90%;
  border-radius: 64px;
  margin: 0 auto;
}

.cardImage:hover {
  cursor: pointer;
  filter: brightness(50%);
}

.modal-content p{
  color: black;
  font-size: 18px;
  line-height: 1em;
  margin: 14px 0 15px 0;
}

.modal-footer div{
  color: black;
  font-size: 18px;
  line-height: 1em;
}

.modal {
  padding-bottom: 17px;
}

div.fade.modal.show{
  padding: 0 0 0 17px;
  background-color: transparent;
}

div.modal-header{
  color: black;
  font-size: 24px;
  letter-spacing: 0.8px;
  
  display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    /* padding: var(--bs-modal-header-padding); */
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius);
    padding: 60px 16px 16px 16px;
}

.modal.show .modal-dialog {
  transform: none;
  margin-top: 20px;
  margin-bottom: 0px;
}

.clickInfo {
  position: absolute;
  top: 50%;  
  left: 50%; 
  transform: translate(-50%, -50%);
  color: var(--font-color);
  display: none;
}

.cardImage{
  width: 100%;
  height: inherit;
}

.modal-body {
  overflow: none;
}

.cardImage:hover + .clickInfo {
  display: block;
}

.modal-backdrop.show {
  opacity: .1 !important;
}

#portfolio_model_link {
  padding: 0 0 1rem 1rem;
  font-size: 1rem;
  
  text-decoration: none;
}

#portfolio_model_link:link {
  text-decoration: none;
}

#cardContainer{
  width: 50%;
  padding: 0;
  border: none;
}

#cardContainer.card{
  padding: 0;
  margin: 0;
  overflow: none;
}

@media only screen and (max-width: 600px){
  #cardContainer {
    width: 100%;
    padding: 0.5rem 2.5rem;
    border: none;
  }
}
/* Contact Form CSS */
.contact {
  background-color: #121212 !important;
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

.Contact_P{
  text-align: center;
}


/* General CSS */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.modal-image {
  /* Styles for the image within the modal */
  cursor: pointer;
}

/* Additional styles for fullscreen view */
.fullscreen-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-image img {
  max-width: 65%;
  max-height: 65%;
}

.nested-modal, 
.no-anime{
  transition: none !important;
  animation: none !important;
  transform: none !important;
  opacity: 1 !important;
}

.thetop{
  z-index: 9999 !important;
}